import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import {
  APP_SETTINGS_MODULE,
  USER_MANAGER_MODULE,
} from "@/constants/storeNames";

const appSettings = namespace(APP_SETTINGS_MODULE);
const userManager = namespace(USER_MANAGER_MODULE);

@Component({
  components: { MaterialIcon },
})
export default class ErrorPageMixin extends Vue {
  @appSettings.Mutation toggleVisibleHeader!: (hide: boolean) => void;
  @userManager.State loading!: boolean;
  @userManager.Getter getHomeRoute!: string;

  created(): void {
    this.toggleVisibleHeader(true);
  }

  @userManager.Action getUserInfo!: () => Promise<void>;

  destroyed(): void {
    this.toggleVisibleHeader(false);
  }

  async redirectToHomePage(): Promise<void> {
    await this.getUserInfo();
    await this.$router.push({
      path: this.getHomeRoute,
    });
  }
}
